/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_cognito_identity_pool_id": "ap-southeast-1:da32c042-bb5d-4609-a567-83e96e4fa758",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_RBLC6Q7AY",
    "aws_user_pools_web_client_id": "11n5t8gsd3pn2iefl239h05bs0",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "gieserviceapi",
            "endpoint": "https://k3j9lusrba.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        },
        {
            "name": "gieserviceloggerapi",
            "endpoint": "https://hu8vx1t119.execute-api.ap-southeast-1.amazonaws.com/prod",
            "region": "ap-southeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "gieservicereact1a896394d8254c1785f499748ef8b05b94726-prod",
    "aws_user_files_s3_bucket_region": "ap-southeast-1"
};


export default awsmobile;
